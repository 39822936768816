.call-to-action {
  z-index: 2000;
  font-family: CodeBold;
  font-size: 28px;
  position: absolute;
  text-align: left;
  top: 0;
  list-style: none;
  padding: 20px 30px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  right: 0;
  margin: 0;
}

.call-to-action li {
  line-height: 36px;
}

.call-to-action li:hover {
  /*animation: rubberBand 1s;*/
}

.call-to-action a {
  color: #FFFFFF;/* # ED1F78;*/
}

.invert {
  color: black
}

.call-to-action a:hover {
  background-color: #ED1F78;
  box-shadow: 0px -2px 0px 4px #ED1F78;/*10px 0 0px 0px #ED1F78, -10px 0 0px 0px #ED1F78;*/
  text-decoration: none;
}

.dropdown-content {
  display: none;
  font-size: 24px;
  padding: 0;
}

.dropdown-content {
  list-style: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.call-to-action .dropdown-content a:hover {
  background-color: transparent;
  color: #ED1F78;
  text-decoration: none;
  box-shadow: 0px 0px 0px 0px #ED1F78;/*10px 0 0px 0px #ED1F78, -10px 0 0px 0px #ED1F78;*/

}

@media (max-width: 576px) {
  .call-to-action {
    text-align: center;
    margin: auto;
    left: 0;
  }
}
