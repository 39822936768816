@font-face {
    font-family: CodeBold;
    src: url(./assets/fonts/CODE-Bold.otf);
}

@font-face {
    font-family: SofiaPro-Black;
    src: url(./assets/fonts/SofiaProBlack.otf);
}

@font-face {
    font-family: SofiaPro-Regular;
    src: url(./assets/fonts/SofiaProRegular.otf);
}

body {
  margin: 0;
  padding: 0;
  font-family: SofiaPro-Regular;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  font-family: CodeBold;
}

h2 {
  color: #50247f;
  text-align: center;
  font-size: 48px;
  margin-bottom: 0px;
}

p {
  padding: 0px 24px;
}
