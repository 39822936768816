header {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  clip-path: polygon(0 0, 100% 0, 100% 100%, calc(50% + 100px) 100%, 50% calc(100% - 50px), calc(50% - 100px) 100%, 0 100%)
}

.slider {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  clip-path: polygon(0 0, 100% 0, 100% 100%, calc(50% + 100px) 100%, 50% calc(100% - 50px), calc(50% - 100px) 100%, 0 100%)
}

@media (max-width: 576px) {
  .slide, .slider {
    height: 100vh;
  }
}


.logo {
  margin-top: calc(40vh - 350px);
  width: 80vw;
  max-width: 500px;
}

@media (max-width: 768px) {
  .logo {
    margin-top: calc(20vh - 250px);
  }
}

@media (max-width: 576px) {
  .logo {
    margin-top: calc(10vh - 50px);
  }
}
