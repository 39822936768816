.footer {
  margin-top: -50px;
  position: relative;
  z-index: 100;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50vw 50px 50vw;
  border-color: transparent transparent #50247f transparent;
}

.purple {
  background-color: #50247f;
  color: rgba(255,255,255,0.8);
  font-family: SofiaPro-Regular;
  padding: 18px 0px;
  line-height: 1.2;
  font-size: 18px;
}

a {
  color: #FFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

b {
  color: #FFF;
  font-family: SofiaPro-Black;
}

.purple p {
  margin: 0;
}

.links {
  margin-top: 12px;
  margin-bottom: 8px;
  text-align: right;
}

.links a {
  color: rgba(255,255,255,0.5);
  margin-left: 10px;
  font-size: 12px;
}

.line {
  border-right: 2px solid rgba(255,255,255,0.2);
}

.line:last-child {
  border-right: 0px;
}

.underline {
  background-color: #f0aec4;
  width: 100%;
  height: 12px;
}

.copyright {
  font-size: 12px;
  text-align: right;
  color: rgba(255,255,255,0.4);
}

.michelin {
  margin-top: 16px;
}

.michelin-stars {
  width: 70px;
}

.icons {
  margin-top: 2px;
  text-align: right;
}

.icon {
  width: 30px;
  margin-left: 6px;
}

.icon:hover {
  opacity: 0.4;
  animation: rubberBand 1s;
}

@media (max-width: 768px) {
  .triangle {
    border-width: 0 50vw 25px 50vw;
  }

  .line {
    text-align: center;
    border-right: 0px;
    border-bottom: 2px solid rgba(255,255,255,0.2);
    padding: 40px 40px;
  }

  .line:first-child {
    padding-top: 0;
  }

  .line:last-child {
    border-bottom: 0px;
  }

  .copyright, .links {
    text-align: center;
  }
}
